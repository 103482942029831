<template>
  <div>
    <v-card flat color="accent rounded-0">
      <v-row>
        <v-col md="2">
          <!-- <box-regular username="Controllers"></box-regular> -->
        </v-col>
      </v-row>
    </v-card>
    <div class="common-container my-10">
      <h2>
        {{ $t("locations.update") }}
      </h2>
    </div>
    <div class="common-container my-10">
      <div>
        <form class="d-flex flex-column">
          <v-row>
            <v-col cols="12" md="6" class="col-md-6">
              <v-text-field
                :label="$t(`locations.description`)"
                outlined
                v-model="input.description"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="col-md-6">
              <v-select
                v-model="address.country"
                :items="countries"
                item-text="title"
                item-value="val"
                :label="$t(`locations.country`)"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="col-md-6">
              <v-text-field
                :label="$t(`locations.city`)"
                outlined
                v-model="address.city"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="col-md-6">
              <v-text-field
                :label="$t(`locations.pc`)"
                outlined
                v-model="address.postal_code"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="col-md-6">
              <v-text-field
                :label="$t(`locations.address`)"
                outlined
                v-model="address.street_name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="col-md-6">
              <v-text-field
                :label="$t(`locations.number`)"
                outlined
                v-model="address.street_num"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="common-container my-10">
            <h3>
              {{ $t("locations.flow") }}
            </h3>
          </div>
          <v-row>
            <v-col cols="12" md="6" class="select-box col-md-6">
              <v-select
                v-model="origins"
                :items="locations"
                :menu-props="{ maxHeight: '400' }"
                :label="$t(`locations.select_origins_header`)"
                item-text="description"
                item-value="id"
                outlined
                multiple
                persistent-hint
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" class="select-box col-md-6">
              <v-select
                v-model="destinations"
                :items="locations"
                :menu-props="{ maxHeight: '400' }"
                :label="$t(`locations.select_destinations_header`)"
                item-text="description"
                item-value="id"
                outlined
                multiple
                persistent-hint
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" class="select-box col-md-6">
              <v-select
                v-model="input.first"
                :items="select_first"
                :menu-props="{ maxHeight: '400' }"
                :label="$t(`locations.first`)"
                :item-text="`name`"
                :item-value="`value`"
                outlined
                persistent-hint
              ></v-select>
            </v-col>
          </v-row>
        </form>
        <v-col cols="12" md="2" class="d-flex">
          <v-btn class="" color="primary" @click="update_location">
            {{ $t("common.update") }}
          </v-btn>
        </v-col>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import i18n from "@/i18n";

export default {
  name: "location-detail",
  async mounted() {
    this.input = await this.fetchLocationDetail(this.$route.params.id);
    this.new_flow = await this.fetchFlow(this.$route.params.id);
    this.locations = await this.fetchLocationsTable();
    this.address = this.input.address;
    this.origins = this.new_flow.origins;
    this.destinations = this.new_flow.destinations;
  },
  data() {
    return {
      input: {},
      locations: [],
      select_first: [
        { name: i18n.t("common.true"), value: true },
        { name: i18n.t("common.false"), value: false },
      ],
      new_flow: [],
      origins: [],
      destinations: [],
      address: {},
      params: {},
      countries: [
        { title: "Afganistan", val: "AF" },
        { title: "Islas de Åland", val: "AX" },
        { title: "Albania", val: "AL" },
        { title: "Argelia", val: "DZ" },
        { title: "Samoa Americana", val: "AS" },
        { title: "Andorra", val: "AD" },
        { title: "Angola", val: "AO" },
        { title: "Anguila", val: "AI" },
        { title: "Antartida", val: "AQ" },
        { title: "Antigua y Barbuda", val: "AG" },
        { title: "Armenia", val: "AM" },
        { title: "Afganistan", val: "AF" },
        { title: "Aruba", val: "AW" },
        { title: "Australia", val: "AU" },
        { title: "Austria", val: "AT" },
        { title: "Azerbayan", val: "AZ" },
        { title: "Afganistan", val: "AF" },
        { title: "Afganistan", val: "AF" },
        { title: "Bahamas", val: "BS" },
        { title: "Barein", val: "BH" },
        { title: "Bangladesh", val: "BD" },
        { title: "Barbados", val: "BB" },
        { title: "Turkia", val: "TR" },
        { title: "España", val: "ES" },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchLocationDetail: "locations/fetchLocationDetail",
      fetchLocationsTable: "locations/fetchLocationsTable",
      fetchFlow: "locations/fetchFlow",
      updateLocation: "locations/updateLocation",
    }),
    update_location() {
      let data_to_update = { description: this.input.description };
      data_to_update.address = { address: this.address };
      data_to_update.origins = this.origins;
      data_to_update.destinations = this.destinations;
      data_to_update.first = this.input.first;
      return new Promise((resolve, reject) => {
        Vue.prototype.$api.locations
          .updateLocation(this.$route.params.id, data_to_update)
          .then(({ data }) => {
            resolve(data);
            this.$router.push({ name: "flows" });
          })
          .catch((error) => reject(error));
      });
    },
  },
  computed: {
    ...mapGetters({
      locationDetail: "locations/getLocationDetail",
      locationsTable: "locations/getLocationsTable",
      flow: "locations/getFlow",
    }),
  },
};
</script>

<style></style>
